var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "popup_wrap",
      staticStyle: { width: "1500px", height: "800px" },
    },
    [
      _c(
        "button",
        {
          staticClass: "layer_close",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.$emit("close")
            },
          },
        },
        [_vm._v("close")]
      ),
      _c("div", { staticClass: "popup_cont" }, [
        _c("h1", { staticClass: "page_title" }, [
          _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.001"))),
        ]),
        _c(
          "div",
          { staticClass: "content_box mt10", staticStyle: { height: "700px" } },
          [
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.002"))),
            ]),
            _c("table", { staticClass: "tbl_col" }, [
              _vm._m(0),
              _c("thead", [
                _c("tr", [
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.003"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.005"))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.003"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.006"))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.004"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.005"))),
                  ]),
                  _c("th", [
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.004"))),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.006"))),
                  ]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.007")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.008")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.009")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.010")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.011")))]),
                  _c("th", [_vm._v(_vm._s(_vm.$t("cp.CTRL020P150.012")))]),
                ]),
              ]),
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.porCtrYn,
                          expression: "info.keyCheck.porCtrYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk11",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.porCtrYn)
                          ? _vm._i(_vm.info.keyCheck.porCtrYn, null) > -1
                          : _vm.info.keyCheck.porCtrYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.porCtrYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "porCtrYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "porCtrYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "porCtrYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(1),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.porPlcYn,
                          expression: "info.keyCheck.porPlcYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk12",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.porPlcYn)
                          ? _vm._i(_vm.info.keyCheck.porPlcYn, null) > -1
                          : _vm.info.keyCheck.porPlcYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.porPlcYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "porPlcYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "porPlcYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "porPlcYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(2),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.dlyCtrYn,
                          expression: "info.keyCheck.dlyCtrYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk13",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.dlyCtrYn)
                          ? _vm._i(_vm.info.keyCheck.dlyCtrYn, null) > -1
                          : _vm.info.keyCheck.dlyCtrYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.dlyCtrYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "dlyCtrYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "dlyCtrYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "dlyCtrYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(3),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.dlyPlcYn,
                          expression: "info.keyCheck.dlyPlcYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk14",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.dlyPlcYn)
                          ? _vm._i(_vm.info.keyCheck.dlyPlcYn, null) > -1
                          : _vm.info.keyCheck.dlyPlcYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.dlyPlcYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "dlyPlcYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "dlyPlcYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "dlyPlcYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(4),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.itemYn,
                          expression: "info.keyCheck.itemYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk15",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.itemYn)
                          ? _vm._i(_vm.info.keyCheck.itemYn, null) > -1
                          : _vm.info.keyCheck.itemYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.itemYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "itemYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "itemYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "itemYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(5),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.bkgShipperYn,
                          expression: "info.keyCheck.bkgShipperYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk16",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.bkgShipperYn)
                          ? _vm._i(_vm.info.keyCheck.bkgShipperYn, null) > -1
                          : _vm.info.keyCheck.bkgShipperYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.bkgShipperYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "bkgShipperYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "bkgShipperYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "bkgShipperYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(6),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.actShipperYn,
                          expression: "info.keyCheck.actShipperYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk17",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.actShipperYn)
                          ? _vm._i(_vm.info.keyCheck.actShipperYn, null) > -1
                          : _vm.info.keyCheck.actShipperYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.actShipperYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "actShipperYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "actShipperYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "actShipperYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(7),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.hcneShipperYn,
                          expression: "info.keyCheck.hcneShipperYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk18",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.hcneShipperYn)
                          ? _vm._i(_vm.info.keyCheck.hcneShipperYn, null) > -1
                          : _vm.info.keyCheck.hcneShipperYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.hcneShipperYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "hcneShipperYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "hcneShipperYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "hcneShipperYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(8),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.cneeShipperYn,
                          expression: "info.keyCheck.cneeShipperYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk19",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.cneeShipperYn)
                          ? _vm._i(_vm.info.keyCheck.cneeShipperYn, null) > -1
                          : _vm.info.keyCheck.cneeShipperYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.cneeShipperYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "cneeShipperYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "cneeShipperYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "cneeShipperYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(9),
                  ]),
                  _c("td", { staticClass: "chk" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.info.keyCheck.profileYn,
                          expression: "info.keyCheck.profileYn",
                        },
                      ],
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        type: "checkbox",
                        name: "no",
                        id: "chk20",
                        onClick: "return false",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.info.keyCheck.profileYn)
                          ? _vm._i(_vm.info.keyCheck.profileYn, null) > -1
                          : _vm.info.keyCheck.profileYn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.info.keyCheck.profileYn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "profileYn",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.info.keyCheck,
                                  "profileYn",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.info.keyCheck, "profileYn", $$c)
                          }
                        },
                      },
                    }),
                    _vm._m(10),
                  ]),
                ]),
              ]),
            ]),
            _c("div", {
              staticClass: "mt10",
              staticStyle: { width: "100%", height: "140px" },
              attrs: { id: "realgrid" },
            }),
            _c("h2", { staticClass: "content_title" }, [
              _vm._v(_vm._s(_vm.$t("cp.CTRL020P150.013"))),
            ]),
            _c("div", {
              staticClass: "mt10",
              staticStyle: { width: "100%", height: "350px" },
              attrs: { id: "realgrid2" },
            }),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk11" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk12" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk13" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk14" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk15" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk16" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk17" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk18" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk19" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "chk20" } }, [
      _c("span", { staticClass: "offscreen" }, [_vm._v("No")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }