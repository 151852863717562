<template>
  <div class="popup_wrap" style="width:1500px; height:800px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P150.001') }}</h1>
      <div class="content_box mt10" style="height:700px;">
        <h2 class="content_title">{{ $t('cp.CTRL020P150.002') }}</h2>
        <table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>{{ $t('cp.CTRL020P150.003') }}<br>{{ $t('cp.CTRL020P150.005') }}</th>
              <th>{{ $t('cp.CTRL020P150.003') }}<br>{{ $t('cp.CTRL020P150.006') }}</th>
              <th>{{ $t('cp.CTRL020P150.004') }}<br>{{ $t('cp.CTRL020P150.005') }}</th>
              <th>{{ $t('cp.CTRL020P150.004') }}<br>{{ $t('cp.CTRL020P150.006') }}</th>
              <th>{{ $t('cp.CTRL020P150.007') }}</th>
              <th>{{ $t('cp.CTRL020P150.008') }}</th>
              <th>{{ $t('cp.CTRL020P150.009') }}</th>
              <th>{{ $t('cp.CTRL020P150.010') }}</th>
              <th>{{ $t('cp.CTRL020P150.011') }}</th>
              <th>{{ $t('cp.CTRL020P150.012') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="chk">
                <input type="checkbox" name="no" id="chk11" v-model="info.keyCheck.porCtrYn" onClick="return false" style="cursor: pointer;"><label for="chk11"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk12" v-model="info.keyCheck.porPlcYn" onClick="return false" style="cursor: pointer;"><label for="chk12"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk13" v-model="info.keyCheck.dlyCtrYn" onClick="return false" style="cursor: pointer;"><label for="chk13"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk14" v-model="info.keyCheck.dlyPlcYn" onClick="return false" style="cursor: pointer;"><label for="chk14"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk15" v-model="info.keyCheck.itemYn" onClick="return false" style="cursor: pointer;"><label for="chk15"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk16" v-model="info.keyCheck.bkgShipperYn" onClick="return false" style="cursor: pointer;"><label for="chk16"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk17" v-model="info.keyCheck.actShipperYn" onClick="return false" style="cursor: pointer;"><label for="chk17"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk18" v-model="info.keyCheck.hcneShipperYn" onClick="return false" style="cursor: pointer;"><label for="chk18"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk19" v-model="info.keyCheck.cneeShipperYn" onClick="return false" style="cursor: pointer;"><label for="chk19"><span class="offscreen">No</span></label>
              </td>
              <td class="chk">
                <input type="checkbox" name="no" id="chk20" v-model="info.keyCheck.profileYn" onClick="return false" style="cursor: pointer;"><label for="chk20"><span class="offscreen">No</span></label>
              </td>
            </tr>
            <!--tr>
              <td><input type="text"></td>
              <td>PUS</td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
              <td><input type="text"></td>
            </tr-->
          </tbody>
        </table>
        <div id="realgrid" class="mt10" style="width: 100%; height: 140px" />
        <h2 class="content_title">{{ $t('cp.CTRL020P150.013') }}</h2>
        <div id="realgrid2" class="mt10" style="width: 100%; height: 350px" />
        <!--table class="tbl_col">
          <colgroup>
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
            <col width="10%">
          </colgroup>
          <thead>
            <tr>
              <th>POR(COUNTRY)</th>
              <th>POR(PORT)</th>
              <th>DLY(COUNTRY)</th>
              <th>DLY(PORT)</th>
              <th>ITEM</th>
              <th>BKG SHIPPER</th>
              <th>ACT SHIPPER</th>
              <th>HCNE SHIPPER</th>
              <th>CNEE SHIPPER</th>
              <th>PROFILE</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in info.targetShipper" :key="idx">
              <td>{{item.porCtrNm}}</td>
              <td>{{item.porPlcNm}}</td>
              <td>{{item.dlyCtrNm}}</td>
              <td>{{item.dlyPlcNm}}</td>
              <td>{{item.bkgShipperNm}}</td>
              <td>{{item.actShipperNm}}</td>
              <td>{{item.hcneShipperNm}}</td>
              <td>{{item.cneeShipperNm}}</td>
              <td>{{item.itemNm}}</td>
              <td>{{item.profileNm}}</td>
            </tr>
          </tbody>
        </table-->
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
import scenarioResult from '@/api/rest/cp/scenarioResult'

let gridView = GridView
let provider = LocalDataProvider

let gridView2 = GridView
let provider2 = LocalDataProvider

const fields = [
  { fieldName: 'porCtrNm', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrNm', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'bkgShipperNm', dataType: 'text' },
  { fieldName: 'actShipperNm', dataType: 'text' },
  { fieldName: 'hcneShipperNm', dataType: 'text' },
  { fieldName: 'cneeShipperNm', dataType: 'text' },
  { fieldName: 'itemGrpNm', dataType: 'text' },
  { fieldName: 'itemNm', dataType: 'text' },
  { fieldName: 'profileNm', dataType: 'text' }
]
const columns = [
  { name: 'porCtrNm', fieldName: 'porCtrNm', header: { text: app.$t('cp.CTRL020P150.003') + app.$t('cp.CTRL020P150.005') }, editable: false, width: 70, styleName: 'text_left' },
  { name: 'porPlcNm', fieldName: 'porPlcNm', header: { text: app.$t('cp.CTRL020P150.003') + app.$t('cp.CTRL020P150.006') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'dlyCtrNm', fieldName: 'dlyCtrNm', header: { text: app.$t('cp.CTRL020P150.004') + app.$t('cp.CTRL020P150.005') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'dlyPlcNm', fieldName: 'dlyPlcNm', header: { text: app.$t('cp.CTRL020P150.004') + app.$t('cp.CTRL020P150.006') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'itemGrpNm', fieldName: 'itemGrpNm', header: { text: app.$t('cp.CTRL020P150.014') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'itemNm', fieldName: 'itemNm', header: { text: app.$t('cp.CTRL020P150.007') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'bkgShipperNm', fieldName: 'bkgShipperNm', header: { text: app.$t('cp.CTRL020P150.008') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'actShipperNm', fieldName: 'actShipperNm', header: { text: app.$t('cp.CTRL020P150.009') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'hcneShipperNm', fieldName: 'hcneShipperNm', header: { text: app.$t('cp.CTRL020P150.010') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'cneeShipperNm', fieldName: 'cneeShipperNm', header: { text: app.$t('cp.CTRL020P150.011') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'profileNm', fieldName: 'profileNm', header: { text: app.$t('cp.CTRL020P150.012') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } }
]

const fields2 = [
  { fieldName: 'porCtrNm', dataType: 'text' },
  { fieldName: 'porPlcNm', dataType: 'text' },
  { fieldName: 'dlyCtrNm', dataType: 'text' },
  { fieldName: 'dlyPlcNm', dataType: 'text' },
  { fieldName: 'bkgShipperNm', dataType: 'text' },
  { fieldName: 'actShipperNm', dataType: 'text' },
  { fieldName: 'hcneShipperNm', dataType: 'text' },
  { fieldName: 'cneeShipperNm', dataType: 'text' },
  { fieldName: 'itemNm', dataType: 'text' },
  { fieldName: 'profileNm', dataType: 'text' }
]
const columns2 = [
  { name: 'porCtrNm', fieldName: 'porCtrNm', header: { text: app.$t('cp.CTRL020P150.003') + app.$t('cp.CTRL020P150.005') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'porPlcNm', fieldName: 'porPlcNm', header: { text: app.$t('cp.CTRL020P150.003') + app.$t('cp.CTRL020P150.006') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'dlyCtrNm', fieldName: 'dlyCtrNm', header: { text: app.$t('cp.CTRL020P150.004') + app.$t('cp.CTRL020P150.006') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'dlyPlcNm', fieldName: 'dlyPlcNm', header: { text: app.$t('cp.CTRL020P150.004') + app.$t('cp.CTRL020P150.005') }, editable: false, width: 90, styleName: 'text_left' },
  { name: 'itemNm', fieldName: 'itemNm', header: { text: app.$t('cp.CTRL020P150.007') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'bkgShipperNm', fieldName: 'bkgShipperNm', header: { text: app.$t('cp.CTRL020P150.008') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'actShipperNm', fieldName: 'actShipperNm', header: { text: app.$t('cp.CTRL020P150.009') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'hcneShipperNm', fieldName: 'hcneShipperNm', header: { text: app.$t('cp.CTRL020P150.010') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'cneeShipperNm', fieldName: 'cneeShipperNm', header: { text: app.$t('cp.CTRL020P150.011') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'profileNm', fieldName: 'profileNm', header: { text: app.$t('cp.CTRL020P150.012') }, editable: false, width: 90, styleName: 'text_left', renderer: { showTooltip: true } }
]

export default {
  name: 'ApiListPopup',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          scenarioCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      info: {
        keyCheck: {},
        keyInput: [],
        targetShipper: []
      }
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    const $vm = this

    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리고 헤더 높이 조정
    gridView.header.heights = [38]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.setRowIndicator({
      visible: false
    })
    gridView.footers.visible = false
    // 그리드 필드 생성
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    gridView2.setDataSource(provider2)
    // 그리고 헤더 높이 조정
    gridView2.header.heights = [38]
    gridView2.displayOptions.rowHeight = 34
    gridView2.displayOptions.fitStyle = 'even'
    gridView2.footers.visible = false
    gridView2.setStateBar({
      visible: false
    })
    gridView2.setCheckBar({
      visible: false
    })
    gridView2.setRowIndicator({
      visible: false
    })
    gridView2.footers.visible = false
    // 그리드 필드 생성
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)
    gridView2.displayOptions.selectionStyle = 'rows'
    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    $vm.searchData()
  },
  methods: {
    searchData: function () {
      scenarioResult.detail(this.parentInfo.scenarioCd).then(response => {
        this.info.keyCheck = response.data.keyCheck
        this.info.keyInput = response.data.keyInput
        this.info.targetShipper = response.data.targetShipper
        provider.setRows(this.info.keyInput)
        provider2.setRows(this.info.targetShipper)
      }).catch(err => {
        console.log(err)
      })
    },
    layerClose: function () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    }
  }
}
</script>
